import { WSAlert, WSAlertProps } from "@wingspanhq/fe-component-library";
import React, { useMemo } from "react";
import { useBankingAccount } from "../../../query/bookkeeping/queries";
import { useUserId } from "../../../query/hooks/helpers";
import { usePayoutSettings } from "../../../query/payments/queries";
import { selectorDefaultDestination } from "../../../shared/selectors/selectorDefaultDestination";

export const AlertNoPayoutMethod: React.FC = () => {
  const userId = useUserId();
  const queryPayoutSettings = usePayoutSettings(userId);
  const queryBankingAccount = useBankingAccount();

  const alertProps = useMemo((): WSAlertProps | undefined => {
    const hasDefaultDestination =
      queryPayoutSettings.data &&
      !!selectorDefaultDestination(queryPayoutSettings.data);

    const hasNoPayoutMethod =
      !hasDefaultDestination && !queryBankingAccount.data;

    if (hasNoPayoutMethod) {
      return {
        theme: "error",
        icon: "alert",
        title: "You cannot get paid until you add a payout method"
      };
    }
  }, [queryBankingAccount.data, queryPayoutSettings.data]);

  if (
    queryBankingAccount.isLoading ||
    queryPayoutSettings.isLoading ||
    queryBankingAccount.isLoading
  )
    return null;

  if (!alertProps) return null;

  return <WSAlert {...alertProps} />;
};
