import {
  WSDivider,
  WSElement,
  WSGrid,
  WSList,
  WSPageToolbar
} from "@wingspanhq/fe-component-library";
import { useHistory } from "react-router-dom";
import {
  PATH_SETTINGS_PAYMENT_METHODS_ADD_PAYMENT_METHOD,
  PATH_SETTINGS_PAYMENT_METHODS_MANAGE_PAYMENT_METHOD
} from "..";
import { useFeatureFlags } from "../../../../../query/hooks/useFeatureFlags";
import { usePaymentsSettingsManagedByOrganization } from "../../../../../userFeatures/usePaymentsSettingsManagedByOrganization";
import { PayoutSplits } from "../../../../PaymentMethods/components/PayoutSplits";
import { WingspanAccounts } from "../../../../PaymentMethods/components/WingspanAccounts";
import { AlertNoPayoutMethod } from "../../../components/AlertNoPayoutMethod";
import { AlertPendingAccountMicroDeposit } from "../../../components/AlertPendingAccountMicroDeposit";
import { DefaultPaymentMethod } from "../../../components/payment/DefaultPaymentMethod";
import { DefaultPayoutMethod } from "../../../components/payout/DefaultPayoutMethod";

export const RouteIndex: React.FC = () => {
  const featureFlags = useFeatureFlags();
  const hasManagedPayments = !!usePaymentsSettingsManagedByOrganization();
  const history = useHistory();

  if (hasManagedPayments) {
    return (
      <>
        <WSPageToolbar title="Payment method" />

        <WSGrid gutter={{ m: "3XL" }}>
          <WSGrid.Item span={{ m: "7" }}>
            <WSList gap="2XL">
              <AlertPendingAccountMicroDeposit />
              <DefaultPaymentMethod
                onManage={() => {
                  history.push(
                    PATH_SETTINGS_PAYMENT_METHODS_MANAGE_PAYMENT_METHOD
                  );
                }}
                onAddNew={() => {
                  history.push(
                    PATH_SETTINGS_PAYMENT_METHODS_ADD_PAYMENT_METHOD
                  );
                }}
              />
            </WSList>
          </WSGrid.Item>
        </WSGrid>
      </>
    );
  }
  return (
    <>
      <WSPageToolbar title="Payout & payment methods" />

      <WSGrid gutter={{ m: "3XL" }}>
        <WSGrid.Item span={{ m: "7" }}>
          <WSList gap="2XL">
            <AlertPendingAccountMicroDeposit />
            <AlertNoPayoutMethod />

            <DefaultPayoutMethod />

            <WSElement>
              <DefaultPaymentMethod
                hidable
                onManage={() => {
                  history.push(
                    PATH_SETTINGS_PAYMENT_METHODS_MANAGE_PAYMENT_METHOD
                  );
                }}
                onAddNew={() => {
                  history.push(
                    PATH_SETTINGS_PAYMENT_METHODS_ADD_PAYMENT_METHOD
                  );
                }}
              />
            </WSElement>
          </WSList>
        </WSGrid.Item>
        <WSGrid.Item span={{ m: "5" }}>
          <WSList gap="XL">
            {!featureFlags.data?.accountPause && (
              <>
                <PayoutSplits />
                <WSDivider />
              </>
            )}
            <WingspanAccounts />
          </WSList>
        </WSGrid.Item>
      </WSGrid>
    </>
  );
};
