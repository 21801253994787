import { BulkStatus, IBulkBatch } from "@wingspanhq/payments/dist/interfaces";
import React, { ReactNode, useEffect, useState } from "react";
import {
  WSFlexBox,
  WSIcon,
  WSList,
  WSProgressBar,
  WSText
} from "@wingspanhq/fe-component-library";
import { isNotNil } from "../../../../utils";
import { formatNumber } from "accounting";
import { BulkResource } from "../../types";
import { getBulkQueriesByResource } from "../../query/bulkBatchResourceQueryMap";

const POLL_TIME_IN_MS = 2000;

export interface BulkActionCustomProgressTextArgs {
  formattedTotalItems: string;
  formattedItemsProcessed: string;
  formattedItemsFailed: string;
}

export function BulkBatchImportProgress({
  batchId,
  bulkResource,
  prepareCustomProgressText
}: {
  batchId: string;
  bulkResource: BulkResource;
  prepareCustomProgressText?: (
    status: BulkActionCustomProgressTextArgs
  ) => string;
}) {
  const { useBulkBatch, useBulkBatchImporting, useBulkBatchSummary } =
    getBulkQueriesByResource(bulkResource);

  // Two separate trackers for batch & batch items polling calls
  const [isBatchProcessed, setIsBatchProcessed] = useState(false);
  const [areAllBatchItemsProcessed, setAreAllBatchItemsProcessed] =
    useState(false);

  const queryBulkBatch = useBulkBatch(batchId, {
    enabled: isBatchProcessed
  });

  // we are polling get payable batch endpoint with different queryKey
  // as it's closing the modals
  const qBulkBatch = useBulkBatchImporting(batchId, {
    refetchInterval: POLL_TIME_IN_MS,
    enabled: !isBatchProcessed
  });

  const bulkBatch = qBulkBatch.data as IBulkBatch;

  // query bulk batch summary once batch is processed
  const qBulkBatchItems = useBulkBatchSummary(batchId, undefined, {
    enabled: isBatchProcessed
  });

  const qBulkBatchItemsCompleted = useBulkBatchSummary(
    batchId,
    { filter: { status: { in: [BulkStatus.Complete] } } },
    {
      refetchInterval: POLL_TIME_IN_MS,
      enabled: !areAllBatchItemsProcessed
    }
  );

  const qBulkBatchItemsFailed = useBulkBatchSummary(
    batchId,
    { filter: { status: { in: [BulkStatus.Failed] } } },
    {
      refetchInterval: POLL_TIME_IN_MS,
      enabled: !areAllBatchItemsProcessed
    }
  );

  const totalBatchItemsSize = qBulkBatchItems.data?.listSize ?? 0;
  const completedBatchItemsSize = qBulkBatchItemsCompleted.data?.listSize ?? 0;

  const failedBatchItemsSize = qBulkBatchItemsFailed.data?.listSize ?? 0;

  const processedBatchItemsSize =
    completedBatchItemsSize + failedBatchItemsSize;

  useEffect(() => {
    const isBatchUploadFinished =
      bulkBatch &&
      [BulkStatus.Complete, BulkStatus.Failed].includes(bulkBatch?.status);
    if (isBatchUploadFinished) {
      setIsBatchProcessed(true);
      // refetch bulk batch to show the latest status on UI
      queryBulkBatch.refetch();
    }
  }, [bulkBatch]);

  useEffect(() => {
    if (totalBatchItemsSize === processedBatchItemsSize) {
      setAreAllBatchItemsProcessed(true);
    }
  }, [totalBatchItemsSize, processedBatchItemsSize]);

  if (
    isNotNil(bulkBatch) &&
    isNotNil(processedBatchItemsSize) &&
    isNotNil(totalBatchItemsSize)
  ) {
    const formattedTotalBatchItemsSize = formatNumber(totalBatchItemsSize);
    const formattedProcessedBatchItemsSize = formatNumber(
      processedBatchItemsSize
    );
    const formattedItemsProcessed = formatNumber(
      bulkBatch.statistics?.itemsProcessed || 0
    );
    const formattedItemsFailed = formatNumber(
      bulkBatch.statistics?.itemsFailed || 0
    );
    let progressText: ReactNode = "";
    if (prepareCustomProgressText) {
      progressText = prepareCustomProgressText({
        formattedTotalItems: formattedTotalBatchItemsSize,
        formattedItemsProcessed,
        formattedItemsFailed
      });
    } else {
      const progressTextMap: { [key in BulkStatus]: ReactNode } = {
        [BulkStatus.Open]: "",
        [BulkStatus.Pending]: "Pending, import will begin shortly...",
        [BulkStatus.Processing]: `${formattedProcessedBatchItemsSize} of ${formattedTotalBatchItemsSize} items imported. If you close this page, the progress will continue until it is completed.`,
        [BulkStatus.Complete]: (
          <WSList gap="L" mt="M">
            <WSFlexBox.CenterY>
              <WSIcon block name="check-circle" color="green500" mr="XS" />
              <WSText.ParagraphSm color="gray500">
                {formattedItemsProcessed} of {formattedTotalBatchItemsSize}{" "}
                items imported
              </WSText.ParagraphSm>
            </WSFlexBox.CenterY>

            {(bulkBatch.statistics?.itemsFailed || 0) > 0 && (
              <WSFlexBox.CenterY>
                <WSIcon block name="exit-circle" color="red400" mr="XS" />
                <WSText.ParagraphSm color="gray500">
                  {formattedItemsFailed} of {formattedTotalBatchItemsSize} items
                  failed
                </WSText.ParagraphSm>
              </WSFlexBox.CenterY>
            )}
          </WSList>
        ),
        [BulkStatus.Failed]: (
          <WSFlexBox.CenterY>
            <WSIcon block name="exit-circle" color="red400" mr="XS" />
            <WSText.ParagraphSm color="gray500" py="M">
              {formattedItemsFailed} of {formattedTotalBatchItemsSize} items
              failed
            </WSText.ParagraphSm>
          </WSFlexBox.CenterY>
        )
      };
      progressText = progressTextMap[bulkBatch.status];
    }
    const importPercent = (processedBatchItemsSize * 100) / totalBatchItemsSize;
    return (
      <>
        <WSProgressBar
          percent={importPercent || 0}
          text={progressText}
          noAnimation={isBatchProcessed}
        />
      </>
    );
  }
  return null;
}
