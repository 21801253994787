// bulk batch
export const QUERY_BULK_COLLABORATOR_BATCH_LIST =
  "QUERY_BULK_COLLABORATOR_BATCH_LIST";
export const BULK_CONTRACTOR_BATCH_LIST_SUMMARY =
  "BULK_CONTRACTOR_BATCH_LIST_SUMMARY";
export const QUERY_BULK_COLLABORATOR_BATCH = "QUERY_BULK_COLLABORATOR_BATCH";
export const QUERY_BULK_COLLABORATOR_IMPORTING_BATCH =
  "QUERY_BULK_COLLABORATOR_IMPORTING_BATCH";
export const QUERY_BULK_COLLABORATOR_BATCH_SUMMARY =
  "QUERY_BULK_COLLABORATOR_BATCH_SUMMARY";

// bulk items
export const QUERY_BULK_COLLABORATOR_BATCH_ITEM_LIST =
  "QUERY_BULK_COLLABORATOR_BATCH_ITEM_LIST";
