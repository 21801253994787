import StackdriverErrorReporter from "stackdriver-errors-js";
import { BUILD_ENV } from "./shared/constants/environment";

const apiKey = process.env.REACT_APP_STACKDRIVER_ERRORS;

export let errorReporter: StackdriverErrorReporter;

if (!!apiKey) {
  errorReporter = new StackdriverErrorReporter();
  errorReporter.start({
    key: apiKey,
    projectId: `wingspan-${BUILD_ENV}`,
    service: "wingspan-app",
    version: process.env.version
  });
} else {
  console.warn("Error reporting is disabled. No API key provided.");

  // Fallback to a no-op error reporter
  errorReporter = {
    start: () => {},
    report: async error => {
      console.error(error);
    },
    setUser: () => {}
  };
}
