import {
  IBulkPayableBatch,
  IBulkPayableItem
} from "@wingspanhq/payments/dist/interfaces";
import { WSInfiniteQueryConfig, WSQueryConfig } from "@ws-react-query";
import { useWSInfiniteQuery, useWSQuery } from "../../../../query/helpers";
import {
  concurrentActions,
  ListRequestQuery,
  WSServiceError
} from "../../../../utils/serviceHelper";
import {
  BULK_PAYABLE_BATCH_LIST,
  BULK_PAYABLE_BATCH_LIST_SUMMARY,
  QUERY_ALL_BULK_PAYABLE_BATCH_ITEM_LIST,
  QUERY_BULK_PAYABLE_BATCH,
  QUERY_BULK_PAYABLE_BATCH_ITEM_LIST,
  QUERY_BULK_PAYABLE_BATCH_SUMMARY,
  QUERY_BULK_PAYABLE_IMPORTING_BATCH
} from "./keys";
import flatten from "lodash/flatten";
import { Await } from "../../../../utils";
import times from "lodash/times";
import { BulkBatchesFilters, BulkBatchItemsFilter } from "../../services/types";
import {
  bulkPayableService,
  IPayableBatchesSummary,
  IPayableBatchListRequestQuery
} from "../../services/bulkPayable";

export const useBulkPayableBatch = (
  batchId: string,
  config?: WSQueryConfig<IBulkPayableBatch, WSServiceError>
) => {
  return useWSQuery<IBulkPayableBatch, WSServiceError>(
    [QUERY_BULK_PAYABLE_BATCH, batchId],
    () => bulkPayableService.batch.get(batchId),
    config
  );
};

export const useBulkPayableImportingBatch = (
  batchId: string,
  config?: WSQueryConfig<IBulkPayableBatch, WSServiceError>
) => {
  return useWSQuery<IBulkPayableBatch, WSServiceError>(
    [QUERY_BULK_PAYABLE_IMPORTING_BATCH, batchId],
    () => bulkPayableService.batch.get(batchId),
    config
  );
};

function mapBulkPayablesBatchFilters(filters?: BulkBatchesFilters) {
  // TODO: Remove any
  const apiFilters: any = {};

  if (filters?.status && filters.status.length !== 5) {
    apiFilters.status = {
      in: filters.status
    };
  }

  if (filters?.year) {
    apiFilters.createdAt = {
      ">=": `${filters.year}-01-01`,
      "<=": `${filters.year}-12-31`
    };
  }
  return apiFilters;
}

export const useBulkPayablesBatchesQuery = (
  filters?: BulkBatchesFilters,
  config?: { size?: number } & WSInfiniteQueryConfig<
    IBulkPayableBatch[],
    WSServiceError
  >
) => {
  const size = config?.size || 100;

  const query = useWSInfiniteQuery<IBulkPayableBatch[], WSServiceError>(
    [BULK_PAYABLE_BATCH_LIST, { filters, size }],
    ({ pageParam = 1 }) => {
      return bulkPayableService.batch.list({
        filter: mapBulkPayablesBatchFilters(filters),
        page: {
          size,
          number: pageParam
        },
        sort: filters?.sort
          ? filters.sort
          : {
              createdAt: "desc"
            }
      });
    },
    {
      getNextPageParam: (lastPage, allPages) => {
        if (lastPage.length < size) {
          return undefined;
        } else {
          return allPages.length + 1;
        }
      },
      ...config
    }
  );

  return {
    ...query,
    data: query.data ? flatten(query.data.pages) : undefined
  };
};

export const useQueryPayableBatchesSummary = (
  params?: IPayableBatchListRequestQuery,
  config?: WSQueryConfig<IPayableBatchesSummary, WSServiceError>
) => {
  return useWSQuery<IPayableBatchesSummary, WSServiceError>(
    [BULK_PAYABLE_BATCH_LIST_SUMMARY, params],
    () =>
      bulkPayableService.batch.listSize({
        filter: mapBulkPayablesBatchFilters(params),
        page: { size: 1, number: 1 },
        sort: params?.sort
          ? params.sort
          : {
              createdAt: "desc"
            }
      }),
    {
      refetchOnMount: false,
      retry: false,
      ...config
    }
  );
};

export const useBulkPayableBatchSummary = (
  batchId: string,
  query?: ListRequestQuery<
    BulkBatchItemsFilter,
    {
      createdAt?: "asc" | "desc";
    }
  >,
  config?: WSQueryConfig<
    Await<ReturnType<typeof bulkPayableService.batch.summary>>,
    WSServiceError
  >
) => {
  return useWSQuery<
    Await<ReturnType<typeof bulkPayableService.batch.summary>>,
    WSServiceError
  >(
    [QUERY_BULK_PAYABLE_BATCH_SUMMARY, [batchId, query]],
    () => bulkPayableService.batch.summary(batchId, query),
    config
  );
};

export const useAllBulkPayableBatchItems = (
  batchId: string,
  query?: ListRequestQuery<
    BulkBatchItemsFilter,
    {
      createdAt?: "asc" | "desc";
    }
  >,
  config?: WSQueryConfig<IBulkPayableItem[], WSServiceError>
) => {
  return useWSQuery<IBulkPayableItem[], WSServiceError>(
    [QUERY_ALL_BULK_PAYABLE_BATCH_ITEM_LIST, [batchId, query]],
    async () => {
      const pageSize = 100;
      const listSize = await bulkPayableService.batchItem.listSize(
        batchId,
        query
      );
      const pages = Math.ceil(listSize / pageSize);

      const actions = times(pages).map(
        (_, i) => () =>
          bulkPayableService.batchItem.list(batchId, {
            ...query,
            page: { size: pageSize, number: i + 1 }
          })
      );

      const allPages = await concurrentActions(actions, {
        concurrentLimit: 5
      });

      const allBulkPayableBatchItems = flatten(allPages);

      return allBulkPayableBatchItems;
    },
    {
      refetchOnMount: false,
      retry: false,
      ...config
    }
  );
};

export const useBulkPayableBatchItems = (
  batchId: string,
  query?: ListRequestQuery<
    BulkBatchItemsFilter,
    {
      createdAt?: "asc" | "desc";
    }
  >,
  config?: WSQueryConfig<
    Await<ReturnType<typeof bulkPayableService.batchItem.list>>,
    WSServiceError
  >
) => {
  return useWSQuery<
    Await<ReturnType<typeof bulkPayableService.batchItem.list>>,
    WSServiceError
  >(
    [QUERY_BULK_PAYABLE_BATCH_ITEM_LIST, [batchId, query]],
    () => bulkPayableService.batchItem.list(batchId, query),
    config
  );
};
