import {
  WSCard,
  WSElement,
  WSList,
  WSText,
  toWSMoneyString
} from "@wingspanhq/fe-component-library";
import React from "react";
import { useHistory } from "react-router-dom";
import { useQueryInvoicesRowsSummary } from "../../../Invoicing/queries/useQueryInvoicesRowsSummary";
import { InvoiceRowStatus } from "../../../Invoicing/service";
import { DefaultPayoutMethod } from "../../../Settings/components/payout/DefaultPayoutMethod";

export const SidePanel: React.FC = () => {
  const history = useHistory();

  const queryInovicesRowsSummaryOutstanging = useQueryInvoicesRowsSummary({
    filter: {
      "labels.invoiceType": {
        "!=": "approvedInvoicesPayment"
      },
      status: {
        in: [InvoiceRowStatus.ActionRequired, InvoiceRowStatus.AwaitingClient]
      }
    }
  });

  const queryInovicesRowsSummaryDeposited = useQueryInvoicesRowsSummary({
    filter: {
      "labels.invoiceType": {
        "!=": "approvedInvoicesPayment"
      },
      status: InvoiceRowStatus.Deposited
    }
  });

  const totalOutstandingInvoices =
    queryInovicesRowsSummaryOutstanging.data?.totalValue;

  const totalDepositedInvoices =
    queryInovicesRowsSummaryDeposited.data?.totalValue;

  const showOutstandingInvoicesPanel = !!totalOutstandingInvoices;
  const showDepositedInvoicesPanel = !!totalDepositedInvoices;

  return (
    <WSElement>
      <WSText.Heading4 mb="XL">{"\u00A0"}</WSText.Heading4>

      <WSList gap="XL">
        {showOutstandingInvoicesPanel ? (
          <WSCard
            header={{
              label: { text: "Outstanding invoices" },
              value: {
                value: toWSMoneyString(totalOutstandingInvoices)
              }
            }}
            actions={{
              alignment: "left",
              buttons: [
                {
                  kind: "Link",
                  rightIcon: "chevron-right",
                  onClick: () => {
                    history.push("/member/invoices/outstanding");
                  },
                  label: "See all outstanding invoices"
                }
              ]
            }}
          />
        ) : null}

        {showDepositedInvoicesPanel ? (
          <WSCard
            header={{
              label: { text: "Invoices deposited" },
              value: {
                value: toWSMoneyString(totalDepositedInvoices)
              }
            }}
            actions={{
              alignment: "left",
              buttons: [
                {
                  kind: "Link",
                  rightIcon: "chevron-right",
                  onClick: () => {
                    history.push("/member/invoices/deposited");
                  },
                  label: "See all deposited invoices"
                }
              ]
            }}
          ></WSCard>
        ) : null}

        <DefaultPayoutMethod noDescription />
      </WSList>
    </WSElement>
  );
};
