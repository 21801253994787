import { ReportsColumn } from "../../types";
import {
  IPayrollReportInvoice,
  IPayrollReportLineItem
} from "@wingspanhq/payments/dist/interfaces/api/reports";
import { formatReportsDate } from "../../utils/formatReportsDate";
import { formatReportMoney } from "../../utils/formatReportMoney";
import {
  getRedactedMemberName,
  getUserLegalName
} from "../../../../query/users/selectors";
import { IInvoice } from "@wingspanhq/payments/dist/interfaces";
import { calculateLineItemTotal } from "../../../../Invoices/utils";

export type PayrollTableItem = {
  parentInvoice: IInvoice;
  invoice: IPayrollReportInvoice;
  lineItem: IPayrollReportLineItem;
  key: string;
};
export const COLUMNS: ReportsColumn<PayrollTableItem>[] = [
  {
    label: "Payroll Invoice Number",
    value: "Payroll Invoice Number",
    preselected: true,
    getTableCell(data) {
      return data.parentInvoice.invoiceNumber;
    }
  },
  {
    label: "Payroll Invoice URL",
    value: "Payroll Invoice URL",
    getTableCell(data) {
      return `${window.location.host}/member/invoices/payables/payroll-runs/${data.parentInvoice.invoiceId}`;
    }
  },
  {
    label: "Payroll Date",
    value: "Payroll Date",
    getTableCell(data) {
      return formatReportsDate(data.parentInvoice.dueDate);
    }
  },
  {
    label: "Payroll Debited Date",
    value: "Payroll Debited Date",
    getTableCell(data) {
      return formatReportsDate(data.parentInvoice.events.depositedAt);
    }
  },
  {
    label: "Contractor ID",
    group: "Contractor fields",
    value: "Invoice Collaborator ID",
    getTableCell(data) {
      return data.invoice.memberClientId;
    }
  },
  {
    label: "Contractor External ID",
    group: "Contractor fields",
    value: "Invoice Collaborator External ID",
    preselected: true,
    getTableCell(data) {
      return data.invoice.memberExternalId;
    }
  },
  {
    label: "Contractor Name",
    group: "Contractor fields",
    value: "Invoice Collaborator Name",
    preselected: true,
    getTableCell(data) {
      return (
        getUserLegalName(data.invoice.member.user) ||
        data.invoice.member.profile?.company?.legalBusinessName ||
        data.invoice.member.profile?.company?.name ||
        data.invoice.member.user?.email
      );
    }
  },
  {
    label: "Contractor Preferred Name",
    group: "Contractor fields",
    value: "Invoice Collaborator Preferred Name",
    getTableCell(data) {
      return (data.invoice.member?.user?.profile as any)?.preferredName;
    }
  },
  {
    label: "Contractor Email",
    group: "Contractor fields",
    value: "Collaborator Email",
    getTableCell(data) {
      return data.invoice.member.user.email;
    }
  },
  {
    label: "Contractor Company",
    group: "Contractor fields",
    value: "Invoice Company",
    getTableCell(data) {
      return (
        data.invoice.member.profile?.company?.legalBusinessName ||
        data.invoice.member.profile?.company?.name
      );
    }
  },
  {
    group: "Contractor fields",
    label: "Contractor State",
    value: "Collaborator State",
    getTableCell(data) {
      return data.invoice.member.profile?.address?.state;
    }
  },
  {
    group: "Contractor fields",
    label: "Contractor Country",
    value: "Collaborator Country",
    getTableCell(data) {
      return data.invoice.member.profile?.address?.country;
    }
  },
  {
    label: "Invoice Number",
    value: "Invoice Invoice Number",
    preselected: true,
    getTableCell(data) {
      return data.invoice.invoiceNumber;
    }
  },
  {
    label: "Invoice Amount",
    value: "Invoice Amount",
    getTableCell(data) {
      return formatReportMoney(data.invoice.amount);
    }
  },
  {
    label: "Invoice URL",
    value: "Invoice URL",
    getTableCell(data) {
      return `${window.location.host}/member/invoices/payables/payroll-runs/${data.parentInvoice.invoiceId}/${data.invoice.invoiceId}`;
    }
  },
  {
    label: "Invoice PDF",
    value: "Invoice Invoice PDF",
    getTableCell(data) {
      return data.invoice.invoicePdf;
    }
  },
  {
    label: "Receipt PDF",
    value: "Invoice Receipt PDF",
    getTableCell(data) {
      return data.invoice.receiptPdf;
    }
  },
  {
    label: "Sent Date",
    value: "Invoice Sent Date",
    getTableCell(data) {
      return formatReportsDate(data.invoice.sentDate);
    }
  },
  {
    label: "Pay Date",
    value: "Invoice Pay Date",
    getTableCell(data) {
      return formatReportsDate(data.invoice.paidDate);
    }
  },
  {
    label: "Due Date",
    value: "Invoice Due Date",
    getTableCell(data) {
      return formatReportsDate(data.invoice.dueDate);
    }
  },
  {
    label: "Purchase Order Number",
    value: "Invoice Purchase Order Number",
    getTableCell(data) {
      return data.invoice.purchaseOrderNumber;
    }
  },
  {
    label: "Payout Destination",
    value: "Payout Destination",
    getTableCell(data) {
      return data.invoice.payoutDestination;
    }
  },
  {
    label: "Project Name",
    value: "Invoice Project Name",
    getTableCell(data) {
      return data.invoice.projectName;
    }
  },
  {
    label: "Notes",
    value: "Invoice Notes",
    getTableCell(data) {
      return data.invoice.notes;
    }
  },

  {
    label: "Pre-Approver Name",
    value: "Invoice Pre-Approver Name",
    getTableCell(data) {
      const redactedMember = data.invoice.eventActors?.preApprovedBy;
      return redactedMember ? getRedactedMemberName(redactedMember) : undefined;
    }
  },
  {
    label: "Approver Name",
    value: "Invoice Approver Name",
    getTableCell(data) {
      const redactedMember = data.invoice.eventActors?.approvedBy; // be does not return this yet
      return redactedMember
        ? getRedactedMemberName(redactedMember)
        : data.invoice.approverName;
    }
  },
  {
    label: "Line Item Index",
    value: "Line Item Index",
    group: "Line items fields",
    getTableCell(data) {
      return `${data.lineItem.index}`;
    }
  },
  {
    label: "Line Item Title",
    value: "Line Item Title",
    group: "Line items fields",
    preselected: true,
    getTableCell(data) {
      return data.lineItem.description;
    }
  },
  {
    label: "Line Item Description",
    value: "Line Item Description",
    group: "Line items fields",
    getTableCell(data) {
      return data.lineItem.detail;
    }
  },
  {
    label: "Line Item Discount Description",
    value: "Line Item Discount Description",
    group: "Line items fields",
    getTableCell(data) {
      return data.lineItem.discount?.description;
    }
  },
  {
    label: "Line Item Discount Percentage",
    value: "Line Item Discount Percentage",
    group: "Line items fields",
    getTableCell(data) {
      return data.lineItem.discount?.percentage;
    }
  },
  {
    label: "Line Item Discount Amount",
    value: "Line Item Discount Amount",
    group: "Line items fields",
    getTableCell(data) {
      return formatReportMoney(data.lineItem.discount?.amount);
    }
  },
  {
    label: "Line Item Discount Total",
    value: "Line Item Discount Total",
    group: "Line items fields",
    getTableCell(data) {
      return formatReportMoney(data.lineItem.discountTotal);
    }
  },
  {
    label: "Line Item Amount",
    value: "Line Item Amount",
    group: "Line items fields",
    preselected: true,
    getTableCell(data) {
      const lineItemAmountAfterDiscount = calculateLineItemTotal(
        data.lineItem,
        { applyDiscount: true }
      );
      return formatReportMoney(lineItemAmountAfterDiscount);
    }
  },
  {
    label: "Line Item Quantity",
    value: "Line Item Quantity",
    group: "Line items fields",
    getTableCell(data) {
      return data.lineItem.quantity;
    }
  },
  {
    label: "Line Item Cost Per Unit",
    value: "Line Item Cost Per Unit",
    group: "Line items fields",
    getTableCell(data) {
      return formatReportMoney(data.lineItem.costPerUnit);
    }
  }
];
