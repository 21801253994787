import {
  AccountStatus,
  AccountUsage,
  IAccount,
  IAccountCreateRequest,
  IAccountPurpose,
  IAccountUpdateRequest,
  IAccountVerifyRequest,
  IActivity,
  IActivityCreateRequest,
  IActivityUpdateRequest,
  IApiSessionCreateRequest,
  IApiSessionResponse,
  IApiSessionUpdateRequest,
  IAuthorizationCreateRequest,
  IAuthorizationListResponse,
  IAuthorizedAccountsResponse,
  IBeginEmailVerificationRequest,
  IBeginPhoneVerificationRequest,
  IClient,
  IClientCreateRequest,
  IClientUpdateRequest,
  ICompleteEmailVerificationRequest,
  ICompletePhoneVerificationRequest,
  ICreditCard,
  IGrantedSubscriberResponse,
  ILocationResponse,
  IMember,
  IMemberCreateRequest,
  IMemberUpdateRequest,
  INewUser,
  INewUserCreateResponse,
  INextgenSubscription,
  INextgenSubscriptionCreateRequest,
  INextgenSubscriptionPlan,
  IOccupation,
  IOtpSessionCreateRequest,
  IPaymentMethodWriteRequest,
  IPaymentReceiptWithCharge,
  IPhoneVerificationResponse,
  IPlan,
  IPublicUserResponse,
  ISession,
  ISessionResponse,
  ISubscription,
  ISubscriptionGrantCreateRequest,
  ISubscriptionGrantResponse,
  ISubscriptionGrantUpdateRequest,
  ISubscriptionUpdateRequest,
  IUserByEmailResponse,
  IUserCreateRequest,
  IUserUpdateRequest,
  SubscriptionPackage,
  SubscriptionPackageTier,
  SubscriptionTerm
} from "@wingspanhq/users/dist/lib/interfaces";
import {
  IAccountBase,
  IAccountEvents,
  IAccountNumbers
} from "@wingspanhq/users/dist/lib/interfaces/account";
import {
  IAccountRequirementsRequest,
  IAccountRequirementsResponse
} from "@wingspanhq/users/dist/lib/interfaces/api/account-requirements";
import { IAuthorizationResponse } from "@wingspanhq/users/dist/lib/interfaces/api/authorization";
import { IPasswordResetRequest } from "@wingspanhq/users/dist/lib/interfaces/api/password";
import { IUserTagResponse } from "@wingspanhq/users/dist/lib/interfaces/api/userTag";
import axios, { AxiosRequestConfig } from "axios";
import { errorReporter } from "../errorReporter";
import { IS_PRODUCTION_ENV } from "../shared/constants/environment";
import { WSService } from "../utils/WSService";
import {
  addAdminSessionToken,
  deleteAllAdminSessionTokens,
  deleteRequestingTokenFromURL,
  deleteRequestingUserSessionToken,
  deleteSessionToken,
  pullSessionToken,
  pushSessionToken,
  setRequestingUserSessionToken,
  setWingspanAdminSessionToken
} from "./sessionStorage";

const service = new WSService("/users", [
  {
    regex: /Cast to date failed/,
    message: "Sorry! We require dates to be entered in MM/DD/YYYY format."
  },
  {
    regex: /Provided email already in use/,
    message:
      "Sorry! Looks like someone else is already using that email address, <br/>please reach out to <a href='mailto:support@wingspan.app'>support@wingspan.app</a> if you need further assistance."
  },
  {
    regex: /Invalid (credentials provided|username or password)/,
    message:
      "Sorry! Either your credentials are incorrect, or there is no account associated with this email address."
  }
]);

/* User */

export interface IUserCreateRequestWithCaptcha extends IUserCreateRequest {
  captchaToken: string;
  captchaVersion: string;
  forceCaptcha?: boolean;
}

const QUERY_PARAM_PUBLIC_SIGN_UP = "psu";

const createUser = async (
  data: IUserCreateRequestWithCaptcha,
  isPublicSignUp?: boolean
): Promise<INewUserCreateResponse> => {
  const response = await service.post(
    `/user${isPublicSignUp ? `?${QUERY_PARAM_PUBLIC_SIGN_UP}=1` : ""}`,
    data
  );
  return response.data;
};

const getUser = async (id: string): Promise<INewUser> => {
  const response = await service.get(`/user/${id}`);
  return response.data;
};

const updateUser = async (
  id: string,
  data: IUserUpdateRequest
): Promise<INewUser> => {
  const response = await service.patch(`/user/${id}`, data);
  return response.data;
};

const createPasswordReset = async (
  data: IPasswordResetRequest
): Promise<INewUser> => {
  const response = await service.post("/user/password/reset", data);
  return response.data;
};

const getUserTag = async (id: string): Promise<IUserTagResponse> => {
  const response = await service.get(`/user/tag/${id}`);
  return response.data;
};

const getUserByEmail = async (email: string): Promise<IUserByEmailResponse> => {
  const response = await service.get(`/user/email/${email}`);
  return response.data;
};

const getUserById = async (id: string): Promise<IPublicUserResponse> => {
  const response = await service.get(`/user/public/${id}`);
  return response.data;
};

const getUserLocation = async (): Promise<ILocationResponse> => {
  const response = await service.get("/user/location");
  return response.data;
};

/* Phone number verification */
const beginPhoneNumberVerification = async (
  memberId: string,
  request: IBeginPhoneVerificationRequest
): Promise<IPhoneVerificationResponse> => {
  const response = await service.post(
    `/user/${memberId}/verification/phone`,
    request
  );
  return response.data;
};

const completePhoneNumberVerification = async (
  memberId: string,
  request: ICompletePhoneVerificationRequest
): Promise<IPhoneVerificationResponse> => {
  const response = await service.patch(
    `/user/${memberId}/verification/phone`,
    request
  );
  if (response.data.session) {
    pushSessionToken(response.data?.session?.token);
  }
  return response.data;
};

/* Email verification */
const beginEmailVerification = async (
  memberId: string,
  request: IBeginEmailVerificationRequest
): Promise<void> => {
  const response = await service.post(
    `/user/${memberId}/verification/email`,
    request
  );
  return response.data;
};

const beginExistingEmailVerification = async (
  memberId: string
): Promise<void> => {
  const response = await service.post(
    `/user/${memberId}/verification/existing-email`
  );
  return response.data;
};

const completeEmailVerification = async (
  memberId: string,
  request: ICompleteEmailVerificationRequest
): Promise<void> => {
  const response = await service.patch(
    `/user/${memberId}/verification/email`,
    request
  );
  return response.data;
};

/* Session */

export interface ISessionCreateRequest {
  email?: string;
  password?: string;
  captchaToken?: string;
  captchaVersion?: string;
  forceCaptcha?: boolean;
}

const beginOtpSession = async (
  data: IOtpSessionCreateRequest
): Promise<{ userId: string }> => {
  const response = await service.post("/session/otp", data);
  return response.data;
};

const createOtpSession = async (
  data: ICompletePhoneVerificationRequest
): Promise<ISession> => {
  deleteAllAdminSessionTokens();

  const response = await service.patch("/session/otp", data);
  pushSessionToken(response.data.token);
  const session = response.data;
  window.sessionType = session.sessionType;

  if (window.RESET_PASSWORD_TEST) {
    window.localStorage.setItem("data-session", JSON.stringify(session));
  }

  return session;
};

const createSession = async (
  data: ISessionCreateRequest,
  options?: { remember?: boolean }
): Promise<ISession> => {
  deleteAllAdminSessionTokens();

  const response = await service.post("/session", data);
  pushSessionToken(response.data.token, {
    toSessionStorage: !options?.remember
  });
  const session = response.data;
  window.sessionType = session.sessionType;

  // @ts-ignore
  if (window.RESET_PASSWORD_TEST) {
    window.localStorage.setItem("data-session", JSON.stringify(session));
  }

  return session;
};

const requestUserSession = async (
  data: ISessionCreateRequest
): Promise<ISession> => {
  deleteAllAdminSessionTokens();

  const currentToken = pullSessionToken();
  if (currentToken) {
    const response = await service.post("/session/requesting-user", data);
    pushSessionToken(response.data.token, {
      toSessionStorage: true
    });
    addAdminSessionToken(currentToken);
    setWingspanAdminSessionToken(currentToken);
    return response.data;
  } else {
    throw new Error("You don't have a token");
  }
};

const requestPrincipalUserSession = async (
  data: ISessionCreateRequest
): Promise<ISession> => {
  deleteRequestingUserSessionToken();

  const currentToken = pullSessionToken();
  if (currentToken) {
    const response = await service.post("/session/requesting-user", data);
    pushSessionToken(response.data.token, {
      toSessionStorage: true
    });
    setRequestingUserSessionToken(currentToken);
    return response.data;
  } else {
    throw new Error("You don't have a token");
  }
};

const getSession = async (): Promise<ISessionResponse> => {
  const token = pullSessionToken();

  if (token && token !== "undefined") {
    const response = await service.get(`/session/token/${token}`);
    window.sessionType = response.data.sessionType;

    // Set user to error reporter
    errorReporter.setUser(response.data.userId);

    return response.data;
  } else {
    // Reset user in error reporter
    errorReporter.setUser();
    throw new Error("No authorization token was found");
  }
};

const deleteSession = async (): Promise<void> => {
  const token = pullSessionToken();
  delete window.sessionType;
  deleteSessionToken();
  deleteAllAdminSessionTokens();
  deleteRequestingUserSessionToken();
  deleteRequestingTokenFromURL();
  const response = await service.delete(`/session/token/${token}`);
  return response.data;
};

const createApiSession = async (
  request: IApiSessionCreateRequest
): Promise<IApiSessionResponse> => {
  const response = await service.post("/session/api", request);
  return response.data;
};

const getApiSession = async (id: string): Promise<IApiSessionResponse> => {
  const response = await service.get(`/session/api/${id}`);
  return response.data;
};

const updateApiSession = async (
  id: string,
  request: IApiSessionUpdateRequest
): Promise<IApiSessionResponse> => {
  const response = await service.patch(`/session/api/${id}`, request);
  return response.data;
};

const deleteApiSession = async (id: string): Promise<IApiSessionResponse> => {
  const response = await service.delete(`/session/api/${id}`);
  return response.data;
};

const listApiSessions = async (): Promise<IApiSessionResponse[]> => {
  const response = await service.get("/session/api");
  return response.data;
};

/* Member */

const createMember = async (
  id: string,
  data: IMemberCreateRequest
): Promise<IMember> => {
  const response = await service.post(`/user/member/${id}`, data);
  return response.data;
};

const getMember = async (
  id: string,
  axiosConfig?: AxiosRequestConfig<any>
): Promise<IMember> => {
  const response = await service.get(`/user/member/${id}`, {
    headers: { "x-ws-fe-flow": "none" },
    ...axiosConfig
  });
  return response.data;
};

const updateMember = async (
  id: string,
  data: IMemberUpdateRequest
): Promise<IMember> => {
  const response = await service.patch(`/user/member/${id}`, data);
  return response.data;
};

/* Client */

const createClient = async (
  id: string,
  data: IClientCreateRequest
): Promise<IClient> => {
  const response = await service.post(`/user/client/${id}`, data);
  return response.data;
};

const getClient = async (
  id: string,
  axiosConfig?: AxiosRequestConfig<any>
): Promise<IClient> => {
  const response = await service.get(`/user/client/${id}`, {
    headers: { "x-ws-fe-flow": "none" },
    ...axiosConfig
  });
  return response.data;
};

const updateClient = async (
  id: string,
  data: IClientUpdateRequest
): Promise<IClient> => {
  const response = await service.patch(`/user/client/${id}`, data);
  return response.data;
};

/* Subscription */

const getSubscription = async (): Promise<ISubscription> => {
  const { data } = await service.get("/user/member/subscription/1");
  return data;
};

const createSubscription = async (
  data: ISubscriptionUpdateRequest
): Promise<ISubscription> => {
  const response = await service.post("/user/member/subscription/1", data);
  return response.data;
};

const updateSubscription = async (
  data: ISubscriptionUpdateRequest
): Promise<ISubscription> => {
  const response = await service.patch("/user/member/subscription/1", data);
  return response.data;
};

const deleteSubscription = async (): Promise<ISubscription> => {
  const response = await service.delete("/user/member/subscription/1");
  return response.data;
};

/* Subscription V3 */

const getSubscriptionV3 = async (
  memberId: string,
  axiosConfig?: AxiosRequestConfig<any>
): Promise<ISubscription> => {
  const { data } = await service.get(`/user/member/${memberId}/subscription`, {
    headers: { "x-ws-fe-flow": "none" },
    ...axiosConfig
  });
  return data;
};

const createSubscriptionV3 = async (
  memberId: string,
  data: ISubscriptionUpdateRequest
): Promise<ISubscription> => {
  const response = await service.post(
    `/user/member/${memberId}/subscription`,
    data
  );
  return response.data;
};

const updateSubscriptionV3 = async (
  memberId: string,
  data: ISubscriptionUpdateRequest
): Promise<ISubscription> => {
  const response = await service.patch(
    `/user/member/${memberId}/subscription`,
    data
  );
  return response.data;
};

const deleteSubscriptionV3 = async (
  memberId: string
): Promise<ISubscription> => {
  const response = await service.delete(
    `/user/member/${memberId}/subscription`
  );
  return response.data;
};

type Packages = keyof typeof SubscriptionPackage;
type PackageTiers = keyof typeof SubscriptionPackageTier;
type PackageTerms = keyof typeof SubscriptionTerm;

export type IGCSSubscriptionPackageTier = {
  [key in PackageTerms]: IPackage;
};

export type IGCSSubscriptionPackage = {
  [key in PackageTiers]: IGCSSubscriptionPackageTier;
};

export type IGCSSubscriptionPackages = {
  [key in Packages]: IGCSSubscriptionPackage;
};

export interface IPackage {
  amount: number;
  currency: string;
  stripePriceId: string;
  title: string;
}

const getSubscriptionPackages = async (): Promise<IGCSSubscriptionPackages> => {
  const jsonUrl = IS_PRODUCTION_ENV
    ? "https://content.wingspan.app/platform-config/subscription-packages.json"
    : "https://staging-content.wingspan.app/platform-config/subscription-packages.json";
  const response = await axios.get(jsonUrl);
  const freePackage = {
    [SubscriptionPackage.None]: {
      [SubscriptionPackageTier.Basic]: {
        [SubscriptionTerm.Yearly]: {
          stripePriceId: "",
          title: "Wingspan Membership - None",
          amount: 0,
          currency: "usd"
        },
        [SubscriptionTerm.Monthly]: {
          stripePriceId: "",
          title: "Wingspan Membership - None",
          amount: 0,
          currency: "usd"
        }
      }
    }
  };
  return { ...freePackage, ...response.data };
};

/* Subscription Grant  */
const listSubscriptionGrant = async (): Promise<
  ISubscriptionGrantResponse[]
> => {
  const response = await service.get("/subscription-grant");
  return response.data;
};

const getSubscriptionGrant = async (
  id: string
): Promise<ISubscriptionGrantResponse> => {
  const response = await service.get(`/subscription-grant/${id}`);
  return response.data;
};

const createSubscriptionGrant = async (
  request: ISubscriptionGrantCreateRequest
): Promise<ISubscriptionGrantResponse> => {
  const response = await service.post("/subscription-grant", request);
  return response.data;
};

const updateSubscriptionGrant = async (
  id: string,
  data: ISubscriptionGrantUpdateRequest
): Promise<ISubscriptionGrantResponse> => {
  const response = await service.patch(`/subscription-grant/${id}`, data);
  return response.data;
};

const deleteSubscriptionGrant = async (
  id: string
): Promise<ISubscriptionGrantResponse> => {
  const response = await service.delete(`/subscription-grant/${id}`);
  return response.data;
};

/* Granted subscriber */
const listGrantedSubscribers = async (): Promise<
  IGrantedSubscriberResponse[]
> => {
  const response = await service.get("/granted-subscriber/subscription-grant");
  return response.data;
};

/* Subscription Plan */

const getSubscriptionPlan = async (): Promise<IPlan> => {
  const response = await service.get("/user/member/subscription/plan/1");
  return response.data;
};

const getSubscriptionPlans = async (): Promise<IPlan[]> => {
  const response = await service.get("/user/member/subscription/plan");
  return response.data;
};

/* Subscription Payment History */

const getSubscriptionPaymentHistory = async (): Promise<
  IPaymentReceiptWithCharge[]
> => {
  const response = await service.get(
    "/user/member/subscription/payment/history"
  );
  return response.data;
};

/* Subscription Payment Method */

const getSubscriptionPaymentMethod = async (): Promise<ICreditCard> => {
  const response = await service.get(
    "/user/member/subscription/payment/method"
  );
  return response.data;
};

const createSubscriptionPaymentMethod = async (
  data: IPaymentMethodWriteRequest
): Promise<ICreditCard> => {
  const response = await service.post(
    "/user/member/subscription/payment/method",
    data
  );
  return response.data;
};

const updateSubscriptionPaymentMethod = async (
  data: IPaymentMethodWriteRequest
): Promise<ICreditCard> => {
  const response = await service.patch(
    "/user/member/subscription/payment/method",
    data
  );
  return response.data;
};

// Next gen subscription plans

const listNextGenSubscriptionPlans = async (
  memberId: string
): Promise<Array<INextgenSubscriptionPlan>> => {
  const response = await service.get(
    `/user/member/${memberId}/nextgen-subscription-plan`
  );
  return response.data;
};

const getNextGenSubscriptionPlan = async (
  memberId: string,
  planId: string
): Promise<INextgenSubscriptionPlan> => {
  const response = await service.get(
    `/user/member/${memberId}/nextgen-subscription-plan/${planId}`
  );
  return response.data;
};

// Next gen subscription

const createNextGenSubscription = async (
  memberId: string,
  request: INextgenSubscriptionCreateRequest
): Promise<INextgenSubscription> => {
  const response = await service.post(
    `/user/member/${memberId}/nextgen-subscription`,
    request
  );
  return response.data;
};

const listNextGenSubscriptions = async (
  memberId: string
): Promise<Array<INextgenSubscription>> => {
  const response = await service.get(
    `/user/member/${memberId}/nextgen-subscription`
  );
  return response.data;
};

const getNextGenSubscription = async (
  memberId: string,
  subscriptionId: string
): Promise<INextgenSubscription> => {
  const response = await service.get(
    `/user/member/${memberId}/nextgen-subscription/${subscriptionId}`
  );
  return response.data;
};

const updateNextGenSubscription = async (
  memberId: string,
  subscriptionId: string,
  request: any
): Promise<INextgenSubscription> => {
  const response = await service.patch(
    `/user/member/${memberId}/nextgen-subscription/${subscriptionId}`,
    request
  );
  return response.data;
};

const deleteNextGenSubscription = async (
  memberId: string,
  subscriptionId: string
): Promise<INextgenSubscription> => {
  const response = await service.delete(
    `/user/member/${memberId}/nextgen-subscription/${subscriptionId}`
  );
  return response.data;
};

/* Accounts */

export interface AccountUpdateRequest {
  usedFor?: IAccountPurpose;
  usage?: AccountUsage;
  status?: AccountStatus;
  events?: IAccountEvents;
  // @ts-ignore
  integration?: IAccountBase["integration"];
}

const getAccounts = async (): Promise<IAccount[]> => {
  const response = await service.get("/account");
  return response.data;
};

const getAccount = async (id: string): Promise<IAccount> => {
  const response = await service.get(`/account/${id}`);
  return response.data;
};

const createAccount = async (
  data: IAccountCreateRequest
): Promise<IAccount | IAccount[]> => {
  const response = await service.post("/account", data);
  return response.data;
};

const updateAccount = async (
  id: string,
  data: IAccountUpdateRequest
): Promise<IAccount> => {
  const response = await service.patch(`/account/${id}`, data);
  return response.data;
};

const deleteAccount = async (id: string, deleteTransactions?: boolean) => {
  const response = await service.delete(
    `/account/${id}${deleteTransactions ? "?deleteTransactions=true" : ""}`
  );
  return response.data;
};

const verifyAccount = async (
  id: string,
  data: IAccountVerifyRequest
): Promise<IAccount> => {
  const response = await service.post(`/account/${id}/verify`, data);
  return response.data;
};

/* Account requirements */

const getAccountRequirements = async (
  data: IAccountRequirementsRequest
): Promise<IAccountRequirementsResponse> => {
  const response = await service.post("/account-requirements", data);
  return response.data;
};

/* Sign-in With Google */

export interface ISignInWithGoogleRequest {
  idToken: string;
}

const signInWithGoogle = async (
  data: ISignInWithGoogleRequest & { inviteCode?: string },
  isPublicSignUp?: boolean
): Promise<ISessionResponse> => {
  const response = await service.post(
    `/session/social-auth/google${
      isPublicSignUp ? `?${QUERY_PARAM_PUBLIC_SIGN_UP}=1` : ""
    }`,
    data
  );
  pushSessionToken(response.data.token);
  return response.data;
};

/* Activity */

const getActivities = async (): Promise<IActivity[]> => {
  const response = await service.get("/activity");
  return response.data;
};

const getActivity = async (id: string): Promise<IActivity> => {
  const response = await service.get(`/activity/${id}`);
  return response.data;
};

const createActivity = async (
  data: IActivityCreateRequest
): Promise<IActivity> => {
  const response = await service.post("/activity", data);
  return response.data;
};

const updateActivity = async (
  id: string,
  data: IActivityUpdateRequest
): Promise<IActivity> => {
  const response = await service.patch(`/activity/${id}`, data);
  return response.data;
};

const deleteActivity = async (id: string): Promise<IActivity> => {
  const response = await service.delete(`/activity/${id}`);
  return response.data;
};

/** Occupation */

const getOccupations = async (): Promise<IOccupation[]> => {
  const response = await service.get("/user/occupation");
  return response.data;
};

/** Authorization */

const getAuthorizations = async (
  params: any
): Promise<IAuthorizationListResponse> => {
  const response = await service.get("/authorization", { params });
  return response.data;
};

const getAuthorizationsListSize = async (): Promise<number> => {
  const { headers } = await service.get("/authorization", {
    params: { page: { size: 1 } }
  });
  return Number(headers["x-ws-list-size"]);
};

const getAuthorization = async (
  id: string
): Promise<IAuthorizationResponse> => {
  const response = await service.get(`/authorization/${id}`);
  return response.data;
};

const createAuthorization = async (payload: IAuthorizationCreateRequest) => {
  const response = await service.post("/authorization", payload);
  return response.data;
};

const deleteAuthorization = async (authorizationId: string) => {
  const response = await service.delete(`/authorization/${authorizationId}`);
  return response.data;
};

const getAuthorizedAccounts = async (
  userId: string
): Promise<IAuthorizedAccountsResponse> => {
  const response = await service.get(`/authorization/accounts/${userId}`);
  return response.data;
};

const getAuthorizationScopeGroups = async (requestingUserId?: string) => {
  const response = await service.get(
    requestingUserId
      ? `/authorized-scope-groups/${requestingUserId}`
      : "/authorized-scope-groups"
  );
  return response.data;
};

/* Guest */

const getGuestAccountNumbers = async (payload: {
  accountId: string;
  publicToken: string;
}): Promise<IAccountNumbers> => {
  const response = await service.post("/guest/account-numbers", payload);
  return response.data;
};

export type IdentifyAuthResponse = {
  authenticationStrategy: "Password" | "SingleSignOn";
  authenticationUrl?: string;
};

const identifyAuth = async (payload: {
  email: string;
  captchaToken: string;
  captchaVersion: string;
}): Promise<IdentifyAuthResponse> => {
  const response = await service.post("/authentication", payload);
  return response.data;
};

const singleSignIn = async (payload: { code: string }) => {
  const response = await service.post("/session/single-sign-on", {
    remember: true,
    singleSignOnCode: payload.code
  });
  pushSessionToken(response.data.token, {
    toSessionStorage: true
  });
  const session = response.data;
  window.sessionType = session.sessionType;

  return session as ISession;
};

export type ILinkTokenResponse = {
  expiration: Date;
  linkToken: string;
  userId: string;
};

const createLinkToken = async (): Promise<ILinkTokenResponse> => {
  const response = await service.post("/account-link");
  return response.data;
};

const createFlatfileAuthToken = async (payload: {
  embedId: string;
}): Promise<any> => {
  const response = await service.post("/flatfile-auth-token", payload);
  return response.data;
};

const getOrganizationUser = async (userId: string): Promise<INewUser> => {
  const response = await service.get(`/organization/user/${userId}`);
  return response.data;
};

const getOrganizationUsers = async (params: any): Promise<Array<INewUser>> => {
  const response = await service.get("/organization/user", {
    params
  });
  return response.data;
};

const getOrganizationUsersListSize = async (): Promise<number> => {
  const { headers } = await service.get("/organization/user", {
    params: { page: { size: 1 } }
  });
  return Number(headers["x-ws-list-size"]);
};

const getOrganizationUserSession = async (
  userId: any
): Promise<ISessionResponse> => {
  deleteRequestingUserSessionToken();

  const currentToken = pullSessionToken();
  if (currentToken) {
    const response = await service.get(`/organization/user/${userId}/session`);
    pushSessionToken(response.data.token, {
      toSessionStorage: true
    });
    addAdminSessionToken(currentToken);
    return response.data;
  } else {
    throw new Error("You don't have a token");
  }
};

export const usersService = {
  user: {
    create: createUser,
    get: getUser,
    update: updateUser,
    passwordReset: {
      create: createPasswordReset
    },
    tag: {
      get: getUserTag
    },
    email: {
      get: getUserByEmail
    },
    public: {
      get: getUserById
    },
    occupation: {
      list: getOccupations
    },
    location: {
      get: getUserLocation
    },
    phoneVerification: {
      begin: beginPhoneNumberVerification,
      complete: completePhoneNumberVerification
    },
    emailVerification: {
      begin: beginEmailVerification,
      complete: completeEmailVerification,
      existingEmail: beginExistingEmailVerification
    }
  },
  session: {
    create: createSession,
    createOtpSession,
    beginOtpSession,
    requestUser: requestUserSession,
    requestPrincipalUser: requestPrincipalUserSession,
    get: getSession,
    delete: deleteSession,
    apiToken: {
      list: listApiSessions,
      create: createApiSession,
      get: getApiSession,
      update: updateApiSession,
      delete: deleteApiSession
    },
    socialAuth: {
      google: signInWithGoogle
    }
  },
  member: {
    create: createMember,
    get: getMember,
    update: updateMember
  },
  client: {
    create: createClient,
    get: getClient,
    update: updateClient
  },
  subscription: {
    get: getSubscription,
    create: createSubscription,
    update: updateSubscription,
    delete: deleteSubscription,
    plan: {
      get: getSubscriptionPlan,
      list: getSubscriptionPlans
    },
    payment: {
      history: {
        list: getSubscriptionPaymentHistory
      },
      method: {
        get: getSubscriptionPaymentMethod,
        create: createSubscriptionPaymentMethod,
        update: updateSubscriptionPaymentMethod
      }
    }
  },
  subscriptionV3: {
    get: getSubscriptionV3,
    create: createSubscriptionV3,
    update: updateSubscriptionV3,
    delete: deleteSubscriptionV3,
    packages: {
      list: getSubscriptionPackages
    }
  },
  nextGenSubscription: {
    create: createNextGenSubscription,
    get: getNextGenSubscription,
    list: listNextGenSubscriptions,
    update: updateNextGenSubscription,
    delete: deleteNextGenSubscription,
    plans: {
      list: listNextGenSubscriptionPlans,
      get: getNextGenSubscriptionPlan
    }
  },
  subscriptionGrant: {
    list: listSubscriptionGrant,
    create: createSubscriptionGrant,
    get: getSubscriptionGrant,
    update: updateSubscriptionGrant,
    delete: deleteSubscriptionGrant
  },
  grantedSubscriber: {
    list: listGrantedSubscribers
  },
  account: {
    list: getAccounts,
    get: getAccount,
    create: createAccount,
    update: updateAccount,
    delete: deleteAccount,
    verify: verifyAccount
  },
  accountRequirements: {
    get: getAccountRequirements
  },
  socialAuth: {
    signInWithGoogle
  },
  activity: {
    list: getActivities,
    get: getActivity,
    create: createActivity,
    update: updateActivity,
    delete: deleteActivity
  },
  authentication: {
    identify: identifyAuth,
    singleSignIn: singleSignIn
  },
  authorization: {
    create: createAuthorization,
    list: getAuthorizations,
    listSize: getAuthorizationsListSize,
    get: getAuthorization,
    delete: deleteAuthorization,
    accounts: getAuthorizedAccounts,
    scopeGroups: {
      list: getAuthorizationScopeGroups
    }
  },
  guest: {
    accountNumbers: getGuestAccountNumbers
  },
  accountLink: {
    create: createLinkToken
  },
  flatfileAuthToken: {
    create: createFlatfileAuthToken
  },
  organization: {
    users: {
      get: getOrganizationUser,
      list: getOrganizationUsers,
      listSize: getOrganizationUsersListSize,
      session: getOrganizationUserSession
    }
  }
};
